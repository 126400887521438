// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
}

div {
  margin-top: 20px;
  font-size: clamp(15px, 3vw, 35px);
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iCAAiC;EACjC,iBAAiB;AACnB","sourcesContent":["body {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  justify-items: center;\n  text-align: center;\n}\n\ndiv {\n  margin-top: 20px;\n  font-size: clamp(15px, 3vw, 35px);\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
