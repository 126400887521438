import React, { useEffect, useState } from 'react';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import './App.css';

export default function App() {
  const [apiData, setApiData] = useState({});

  useEffect(() => {
    fetch('sandbox-server.railway.internal/api')
      .then((res) => res.json())
      .then((data) => setApiData(data));
  }, []);

  const { isLoading, error, data } = useVisitorData(
    { extendedResult: true },
    { immediate: true },
    { ignoreCache: true }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error occured: {error.message}</div>;
  }

  return (
    <>
      <div>
        {apiData.users ? (
          apiData.users.map((user, idx) => <h4 key={idx}>{user}</h4>)
        ) : (
          <h2>Loading...</h2>
        )}
      </div>
      <div>
        <div>
          Welcome
          {data.visitorFound ? ' back' : ''}, <span>{data.visitorId}</span>!
        </div>
        <p>Full visitor data:</p>
        <pre>{error ? error.message : JSON.stringify(data, null, 2)}</pre>
      </div>
    </>
  );
}
